import { getProductPageUrlSync } from '@msdyn365-commerce-modules/retail-actions';
import {
    format,
    getPayloadObject,
    getTelemetryAttributes,
    ITelemetryContent,
    onTelemetryClick,
    Button
} from '@msdyn365-commerce-modules/utilities';
import { RatingComponent, AddToWishlistComponent } from '@msdyn365-commerce/components';
import { PriceComponent } from '../custom-components/product-price-component';
import { generateImageUrl, ArrayExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { Quantity } from '../../utilities/quantity';
import { IDobbiesAddedProductToBundle } from '../../../../modules/dobbies-product-collection/dobbies-product-collection';
import { CartModulesStateShareInput } from '../../../../actions/cart-state-share';
import { getCartState } from '@msdyn365-commerce/global-state';
import {
    getDeliveryOptionsAsync,
    getByIdAsync
} from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { cartLineQuantityUpdatedAsync } from '@msdyn365-commerce-modules/cart-utilities';
import { ICartActionResult } from '@msdyn365-commerce/global-state';

import { getCartFromCustomer } from '@msdyn365-commerce/global-state';
import {
    IComponent,
    IComponentProps,
    ICoreContext,
    IGridSettings,
    IImageData,
    IImageSettings,
    Image,
    msdyn365Commerce,
    ICommerceApiSettings
} from '@msdyn365-commerce/core';
import { ProductPrice, AttributeValue, DiscountLine, Cart, CommerceList, LoyaltyCard, SimpleProduct, DeliveryOption } from '@msdyn365-commerce/retail-proxy';
import React, { useRef, useState } from 'react';
import { IProductWithModes } from '../../../../modules/dobbies-search-result-container/components';
import VsiProductBadges, { badgePageType } from '../../utilities/badges/vsi-product-badges';
import { getAllOrgUnitLocationsByArea, GetAllOrgUnitLocationsByAreaInput } from '../../../../actions/get-all-store-location-information';
import { IFullOrgUnitAvailability } from '../../actions/utilities/full-org-unit-availability';

export interface IProductComponentProps extends IComponentProps<{ product?: IProductWithModes; discountLine?: DiscountLine }> {
    isNotificationModal?: boolean;
    className?: string;
    imageSettings?: IImageSettings;
    savingsText?: string;
    freePriceText?: string;
    originalPriceText?: string;
    currentPriceText?: string;
    ratingAriaLabel?: string;
    allowBack?: boolean;
    telemetryContent?: ITelemetryContent;
    quickViewButton?: React.ReactNode;
    inventoryLabel?: string;
    isPriceMinMaxEnabled?: boolean;
    discountLine?: DiscountLine[] | undefined;
    badgeLink?: string;
    showProdPrice?: boolean;
    showDeliveryIcons?: boolean;
    wishlists?: CommerceList[];
    isMobile?: boolean;
    showBanner?: (prodId: number) => void;
    hideBanner?: (prodId: number) => void;
    loyalty?: LoyaltyCard;
    nonClubPlusDiscount?: ProductPrice;
    isProductAddedToBundles?: boolean;
    maxQuantityForBundle?: number;
    redirectToCartEnabled?: boolean;
    isProductBundles?: boolean,
    isQuantityUpdated?: boolean,
    isBundlesModal?: boolean,
    simpleProduct?: SimpleProduct;
    isNewCarouselDesign?: boolean;
    onQuantityChangeListener?(productId: number, newQuantity: number): void;
    onBundleProductCheckBoxSelected?(productId: number): void;
}

interface ILocalImgData {
    primaryUrl: string;
    isPrimaryUrl: boolean;
}
export interface IProductComponent extends IComponent<IProductComponentProps> { }

const PriceComponentActions = {};

const ProductCard: React.FC<IProductComponentProps> = ({
    data,
    context,
    imageSettings,
    savingsText,
    freePriceText,
    originalPriceText,
    currentPriceText,
    ratingAriaLabel,
    allowBack,
    typeName,
    id,
    telemetryContent,
    quickViewButton,
    wishlists,
    discountLine,
    badgeLink,
    showBanner,
    hideBanner,
    showDeliveryIcons = !data.product?.IsMasterProduct,
    isMobile,
    loyalty,
    nonClubPlusDiscount,
    isProductAddedToBundles,
    maxQuantityForBundle,
    redirectToCartEnabled,
    isProductBundles,
    isBundlesModal,
    isQuantityUpdated,
    simpleProduct,
    onQuantityChangeListener,
    onBundleProductCheckBoxSelected,
    isNotificationModal,
    isNewCarouselDesign
}) => {
    // const [displayClubPlusInfo, setDisplayClubPlusInfo] = React.useState(false);
    const [cartData, setCartData] = React.useState<Cart | undefined>(undefined);
    const [updatedProductPrice, setUpdatedProductPrice] = React.useState<number>();
    const [isHover, setIsHover] = useState(false); // for hover effect
    const productImageRef = useRef<HTMLDivElement>(null);
    const [currBundleQuantity, setCurrBundleQuantity] = React.useState<number>(1);
    const [carouselCurrQuantity, setCarouselCurrQuantity] = useState<number>(0);
    const [existsInCartLines, setExistsInCartLines] = useState(false);
    // const displayClubPlus = () => {
    //     setDisplayClubPlusInfo(!displayClubPlusInfo);
    // }
    const product = data.product;
    if (!product) {
        return null;
    }
    //VSI CUSTOMIZATION -START - Task 11167: PLP hover image flip
    const [images, setImages] = useState<ILocalImgData>({ isPrimaryUrl: false, primaryUrl: '' });
    React.useEffect(() => {
        if (isQuantityUpdated) {
            if (isProductBundles && isBundlesModal) {
                getCartState(context.actionContext).then(cartState => {
                    const cartlines = cartState.cart?.CartLines;
                    if (cartlines && cartlines.length) {
                        const cl = cartlines.find(cl => cl.ProductId === product.RecordId);
                        if (cl && cl.Quantity) {
                            setCurrBundleQuantity(cl.Quantity);
                            if (onQuantityChangeListener) {
                                onQuantityChangeListener(product.RecordId, cl.Quantity);
                            }
                        }
                    }
                }).catch(e => { });
                context.actionContext.update(new CartModulesStateShareInput(), { isQuantityUpdated: false });
                return;
            }
            if (isProductBundles && isNotificationModal) {
                getCartState(context.actionContext).then(cartState => {
                    const cartlines = cartState.cart?.CartLines;
                    if (cartlines && cartlines.length) {
                        const cl = cartlines.find(cl => cl.ProductId === product.RecordId);
                        if (cl && cl.Quantity) {
                            setCurrBundleQuantity(cl.Quantity);
                            if (onQuantityChangeListener) {
                                onQuantityChangeListener(product.RecordId, cl.Quantity);
                            }
                        }
                    }
                }).catch(e => { });
                context.actionContext.update(new CartModulesStateShareInput(), { isQuantityUpdated: false });
                return;
            }
            const cookies = context.actionContext.requestContext.cookies;
            const addedProductsToCollection = cookies.get(isNotificationModal ? 'notification_added_products' : 'added_products_bundles')?.value || [];
            if (Array.isArray(addedProductsToCollection)) {
                for (const obj of addedProductsToCollection) {
                    if (obj['productId'] === product.RecordId) {
                        setCurrBundleQuantity(obj['quantity']);
                        if (onQuantityChangeListener) {
                            onQuantityChangeListener(product.RecordId, obj['quantity']);
                        }
                    }
                }
            }
            context.actionContext.update(new CartModulesStateShareInput(), { isQuantityUpdated: false });
        }
    }, [isQuantityUpdated]);

    React.useEffect(() => {
        if (isProductAddedToBundles) {
            resetQuantity();
        }
    }, [isProductAddedToBundles]);

    const resetQuantity = async () => {
        if (isProductBundles) {
            const cartState = await getCartState(context.actionContext);
            const cl = cartState.cart.CartLines?.find(c => c.ProductId === product.RecordId);
            if (cl && cl.Quantity) {
                if (isProductBundles && isBundlesModal) {
                    setCurrBundleQuantity(cl.Quantity);
                }
                if (isProductBundles && isNotificationModal) {
                    setCurrBundleQuantity(cl.Quantity);
                }
            }
        }
    };
    const resetQuantityOnLoad = async () => {
        if (isProductBundles) {
            const cartState = await getCartState(context.actionContext);
            const cl = cartState.cart.CartLines?.find(c => c.ProductId === product.RecordId);
            if (cl && cl.Quantity) {
                if (isProductBundles && isBundlesModal) {
                    setCurrBundleQuantity(cl.Quantity);
                } else if (!isBundlesModal) {
                    setCurrBundleQuantity(1);
                }
                if (isProductBundles && isNotificationModal) {
                    setCurrBundleQuantity(cl.Quantity);
                } else if (!isBundlesModal) {
                    setCurrBundleQuantity(1);
                }
            } else {
                setCurrBundleQuantity(1);
            }
        }
    };


    React.useEffect(() => {
        if (product && product.RecordId) {
            const baseImgUrl = context.request.apiSettings.baseImageUrl;
            const imgName = `Products%2F${product.ItemId}_000_002.png`;
            const fullImgUrl = `${baseImgUrl}${imgName}`;
            if (!fullImgUrl) {
                return;
            } else {
                setImages({ isPrimaryUrl: false, primaryUrl: '' });
            }
            validateMediaLocaionAsync(fullImgUrl)
                .then(result => {
                    if (result[1] === true) {
                        setImages({ isPrimaryUrl: true, primaryUrl: result[0].src! });
                    } else {
                        setImages({ isPrimaryUrl: false, primaryUrl: '' });
                    }
                })
                .catch(error => {
                    context.actionContext.telemetry.exception(error);
                    context.actionContext.telemetry.debug('Unable to validate prodcut images');
                });
        }
    }, [product]);
    //VSI CUSTOMIZATION -END - Task 11167: PLP hover image flip

    let productUrl = getProductPageUrlSync(product.Name || '', product.RecordId, context && context.actionContext, undefined);
    if (allowBack) {
        productUrl = updateProductUrl(productUrl, context);
    }

    const getCartData = async () => {
        const cart = await getCartFromCustomer(context.actionContext);
        setCartData(cart);
        await resetQuantityOnLoad();
    };

    const deSelectProduct = async () => {
        const cartState = await getCartState(context.actionContext);
        const cartlineToRemove = cartState.cart.CartLines?.find(cl => cl.ProductId === product.RecordId);
        if (isProductBundles && isBundlesModal) {
            if (cartlineToRemove) {
                const input = {
                    cartLineIds: [cartlineToRemove.LineId!.toString()]
                };
                cartState.removeCartLines(input).then(() => {
                    context.actionContext.update(new CartModulesStateShareInput(), { isCartUpdated: true });
                })
                    .catch(error => {
                    });
            }
            return;
        }
        if (isProductBundles && isNotificationModal) {
            if (cartlineToRemove) {
                const input = {
                    cartLineIds: [cartlineToRemove.LineId!.toString()]
                };
                cartState.removeCartLines(input).then(() => {
                    context.actionContext.update(new CartModulesStateShareInput(), { isCartUpdated: true });
                })
                    .catch(error => {
                    });
            }
            return;
        }
        const cookies = context.actionContext.requestContext.cookies;
        let productsAddedInBundles = cookies.get(isNotificationModal ? 'notification_added_products' : 'added_products_bundles')?.value || [];
        if (Array.isArray(productsAddedInBundles)) {
            for (let i = 0; i < productsAddedInBundles.length; i++) {
                if (productsAddedInBundles[i]['productId'] === product.RecordId) {
                    delete productsAddedInBundles[i];
                }
            }
            const updatedProductsAddedInBundles: IDobbiesAddedProductToBundle[] = [];
            productsAddedInBundles.map(prod => {
                if (prod && prod['productId']) {
                    const dobbiesProductData: IDobbiesAddedProductToBundle = {};
                    dobbiesProductData.productId = prod['productId'];
                    dobbiesProductData.quantity = prod['quantity'];
                    dobbiesProductData.price = prod['price'];
                    dobbiesProductData.maxQuantity = prod['maxQuantity'];
                    updatedProductsAddedInBundles.push(dobbiesProductData);
                }
            });
            const cookieName = isNotificationModal ? 'notification_added_products' : 'added_products_bundles';
            cookies.set(cookieName, updatedProductsAddedInBundles);
            context.actionContext.update(new CartModulesStateShareInput(), { isCartUpdated: true });
        }
    };

    const _getAllStores = async (
        latitude?: number,
        longitude?: number,
        radius?: number,
        distanceUnitValue?: number
    ): Promise<IFullOrgUnitAvailability[]> => {
        
    
        const orgUnitLocationsInput = new GetAllOrgUnitLocationsByAreaInput(
            latitude,
            longitude,
            radius,
            distanceUnitValue,
            false
        );
        const orgUnitLocations = await getAllOrgUnitLocationsByArea(orgUnitLocationsInput, context.actionContext);
        return orgUnitLocations;
    };

    const onQChangeNewCarouselDesign = async (newValue: number) => {
        const cartState = await getCartState(context.actionContext);
        const cl = cartState.cart.CartLines?.find(c => c.ProductId === product.RecordId);
        const simpleProdResp = await getByIdAsync(
            { callerContext: context.actionContext },
            Number(product.RecordId),
            context.request.apiSettings.channelId
        );
        const simpleProd : SimpleProduct = Array.isArray(simpleProdResp) ? simpleProdResp[0] : simpleProdResp;
        if (cl) {
            if (cl?.Quantity && cl.Quantity > newValue) {
                cartLineQuantityUpdatedAsync(
                    true,
                    cartState,
                    context.actionContext.telemetry,
                    [simpleProd],
                    cl,
                    newValue
                )
                    .then((cartActionResult: ICartActionResult | undefined): boolean => {
                        if (cartActionResult?.status === 'SUCCESS') {
                            setCarouselCurrQuantity(newValue);
                        }
                        return false;
                    }).catch(e => { });
            } else {
                const productList = new Array();
                const quantityToBeAdded = newValue - (cl?.Quantity ? cl?.Quantity : 0);
                productList.push({
                    product: product,
                    count: quantityToBeAdded,
                    enableStockCheck: context.app.config.enableStockCheck
                });
                cartState
                    ?.addProductsToCart(productList)
                    .then(result => {
                        if (result.status === 'SUCCESS') {
                            setCarouselCurrQuantity(newValue);
                        }
                    });
            }
        } else {
            setCarouselCurrQuantity(newValue);
        }
        
    }

    const onQuantityChange = async (newValue: number) => {
        const cartState = await getCartState(context.actionContext);
        const cl = cartState.cart.CartLines?.find(c => c.ProductId === product.RecordId);
        if (isProductBundles && isBundlesModal) {
            if (!cl) {
                if (onQuantityChangeListener) {
                    onQuantityChangeListener(product.RecordId, newValue);
                }
                setCurrBundleQuantity(newValue);
                return;
            }
            if (cl && cl.Quantity && maxQuantityForBundle && simpleProduct) {
                if (newValue > maxQuantityForBundle) {
                    return;
                }
                const diffInQuantity = newValue - cl.Quantity;
                const deliveryOptions = await getDeliveryOptionsAsync(
                    { callerContext: context.actionContext, queryResultSettings: {} },
                    [product.RecordId]
                );
                const emailDeliveryModeCode = context.actionContext.requestContext.channel?.EmailDeliveryModeCode;
                if (diffInQuantity > 0) {
                    const addProductToCartInput = {
                        product: simpleProduct,
                        quantity: diffInQuantity,
                        enableStockCheck: context.app.config.enableStockCheck,
                        availableQuantity: maxQuantityForBundle,
                        isAddEmailDeliveryItemToCart:
                            ArrayExtensions.hasElements(deliveryOptions) &&
                            ArrayExtensions.hasElements(deliveryOptions[0].DeliveryOptions) &&
                            deliveryOptions[0].DeliveryOptions[0].Code === emailDeliveryModeCode
                    };
                    const cookies = context.actionContext.requestContext.cookies;
                    const cncProducts = cookies.get('cnc_products').value || [];
                    if (Array.isArray(cncProducts) && cncProducts.includes(simpleProduct.ItemId)) {
                        const deliveryOptions = await getDeliveryOptionsAsync(
                            { callerContext: context.actionContext, queryResultSettings: {} },
                            [simpleProduct.RecordId]
                        );
                        let cncOption : DeliveryOption | undefined;
                        deliveryOptions.map(dopt => {
                            dopt.DeliveryOptions?.map(delOpt => {
                                if (delOpt.Code === 'ClickCol') {
                                    cncOption = delOpt;
                                }
                            });
                        });
                        const sLocations = await _getAllStores(31.5070767, 74.4315609, 0, 0);
                        const storeLocation = sLocations.find(sl => {
                            return sl.OrgUnitAvailability?.OrgUnitLocation?.InventoryLocationId === cl.FulfillmentStoreId
                        });
                        if (storeLocation && storeLocation.OrgUnitAvailability?.OrgUnitLocation) {
                            cartState.addProductToCart({ product: simpleProduct, count: diffInQuantity, location: storeLocation.OrgUnitAvailability?.OrgUnitLocation, deliveryMode: cncOption?.Code })
                            .then(async result => {
                                if (result.status === 'SUCCESS') {
                                    context.actionContext.update(new CartModulesStateShareInput(), { isQuantityUpdated: true });
                                }
                        }).catch(e => {});
                        }
                    } else {
                        cartState.addProductToCart(addProductToCartInput)
                    .then(async result => {
                        if (result.status === 'SUCCESS') {
                            context.actionContext.update(new CartModulesStateShareInput(), { isQuantityUpdated: true });
                        }
                }).catch(e => {});
                    }
                } else if (diffInQuantity < 0) {
                    cartLineQuantityUpdatedAsync(
                        true,
                        cartState,
                        context.actionContext.telemetry,
                        [simpleProduct],
                        cl,
                        newValue
                    )
                        .then((cartActionResult: ICartActionResult | undefined): boolean => {
                            if (cartActionResult?.status === 'SUCCESS') {
                                context.actionContext.update(new CartModulesStateShareInput(), { isCartUpdated: true });
                                return true;
                            }
                            return false;
                        }).catch(e => { });
                }
                if (onQuantityChangeListener) {
                    onQuantityChangeListener(product.RecordId, newValue);
                }
                setCurrBundleQuantity(newValue);
            }
            return;
        }
        if (isProductBundles && isNotificationModal) {
            if (!cl) {
                if (onQuantityChangeListener) {
                    onQuantityChangeListener(product.RecordId, newValue);
                }
                setCurrBundleQuantity(newValue);
                return;
            }
            if (cl && cl.Quantity && maxQuantityForBundle && simpleProduct) {
                if (newValue > maxQuantityForBundle) {
                    return;
                }
                const diffInQuantity = newValue - cl.Quantity;
                const deliveryOptions = await getDeliveryOptionsAsync(
                    { callerContext: context.actionContext, queryResultSettings: {} },
                    [product.RecordId]
                );
                const emailDeliveryModeCode = context.actionContext.requestContext.channel?.EmailDeliveryModeCode;
                if (diffInQuantity > 0) {
                    const addProductToCartInput = {
                        product: simpleProduct,
                        quantity: diffInQuantity,
                        enableStockCheck: context.app.config.enableStockCheck,
                        availableQuantity: maxQuantityForBundle,
                        isAddEmailDeliveryItemToCart:
                            ArrayExtensions.hasElements(deliveryOptions) &&
                            ArrayExtensions.hasElements(deliveryOptions[0].DeliveryOptions) &&
                            deliveryOptions[0].DeliveryOptions[0].Code === emailDeliveryModeCode
                    };

                    const cookies = context.actionContext.requestContext.cookies;
                    const cncProducts = cookies.get('cnc_products').value || [];
                    if (Array.isArray(cncProducts) && cncProducts.includes(simpleProduct.ItemId)) {
                        const deliveryOptions = await getDeliveryOptionsAsync(
                            { callerContext: context.actionContext, queryResultSettings: {} },
                            [simpleProduct.RecordId]
                        );
                        let cncOption : DeliveryOption | undefined;
                        deliveryOptions.map(dopt => {
                            dopt.DeliveryOptions?.map(delOpt => {
                                if (delOpt.Code === 'ClickCol') {
                                    cncOption = delOpt;
                                }
                            });
                        });
                        const sLocations = await _getAllStores(31.5070767, 74.4315609, 0, 0);
                        const storeLocation = sLocations.find(sl => {
                            return sl.OrgUnitAvailability?.OrgUnitLocation?.InventoryLocationId === cl.FulfillmentStoreId
                        });
                        if (storeLocation && storeLocation.OrgUnitAvailability?.OrgUnitLocation) {
                            cartState.addProductToCart({ product: simpleProduct, count: diffInQuantity, location: storeLocation.OrgUnitAvailability?.OrgUnitLocation, deliveryMode: cncOption?.Code })
                            .then(async result => {
                                if (result.status === 'SUCCESS') {
                                    context.actionContext.update(new CartModulesStateShareInput(), { isQuantityUpdated: true });
                                }
                        }).catch(e => {});
                        }
                    } else {
                    cartState.addProductToCart(addProductToCartInput)
                        .then(async result => {
                            if (result.status === 'SUCCESS') {
                                context.actionContext.update(new CartModulesStateShareInput(), { isCartUpdated: true });
                            }
                        }).catch(e => { });
                    }
                } else if (diffInQuantity < 0) {
                    cartLineQuantityUpdatedAsync(
                        true,
                        cartState,
                        context.actionContext.telemetry,
                        [simpleProduct],
                        cl,
                        newValue
                    )
                        .then((cartActionResult: ICartActionResult | undefined): boolean => {
                            if (cartActionResult?.status === 'SUCCESS') {
                                context.actionContext.update(new CartModulesStateShareInput(), { isCartUpdated: true });
                                return true;
                            }
                            return false;
                        }).catch(e => { });
                }
                if (onQuantityChangeListener) {
                    onQuantityChangeListener(product.RecordId, newValue);
                }
                setCurrBundleQuantity(newValue);
            }
            return;
        }
        const cookies = context.actionContext.requestContext.cookies;
        const addedProductsToCollection = cookies.get(isNotificationModal ? 'notification_added_products' : 'added_products_bundles')?.value || [];
        const updatedProducts: IDobbiesAddedProductToBundle[] = [];
        if (Array.isArray(addedProductsToCollection)) {
            for (const obj of addedProductsToCollection) {
                if (obj['productId'] === product.RecordId) {
                    const valueToBeUpdated = newValue;
                    if (valueToBeUpdated > 0) {
                        updatedProducts.push({ productId: obj['productId'], price: obj['price'], quantity: valueToBeUpdated, maxQuantity: maxQuantityForBundle, inventoryLocationId: obj['inventoryLocationId'] });
                    }
                } else {
                    updatedProducts.push({ productId: obj['productId'], price: obj['price'], quantity: obj['quantity'], maxQuantity: obj['maxQuantity'], inventoryLocationId: obj['inventoryLocationId'] });
                }
            }
            const cookieName = isNotificationModal ? 'notification_added_products' : 'added_products_bundles';
            cookies.set(cookieName, updatedProducts);
        }
        setCurrBundleQuantity(newValue);
        context.actionContext.update(new CartModulesStateShareInput(), { isCartUpdated: true });
        if (onQuantityChangeListener) {
            onQuantityChangeListener(product.RecordId, newValue);
        }
    };

    const checkboxClicked = () => {
        if (isProductAddedToBundles) {
            deSelectProduct();
        } else if (onBundleProductCheckBoxSelected) {
            onBundleProductCheckBoxSelected(product.RecordId);
        }
    }

    React.useEffect(() => {
        if (isNewCarouselDesign) {
            const setCurrQuantity = async () => {
                const cartState = await getCartState(context.actionContext);
                const cl = cartState.cart.CartLines?.find(c => c.ProductId === product.RecordId);
                if (cl && cl.Quantity) {
                    setCarouselCurrQuantity(cl.Quantity);
                    setExistsInCartLines(true);
                } else {
                    setCarouselCurrQuantity(0);
                    setExistsInCartLines(false);
                }
            }
            setCurrQuantity();
            return;
        }
        getCartData();
        setUpdatedProductPrice(product.Price);

        productImageRef.current?.addEventListener('contextmenu', e => {
            e.preventDefault();
        });
    }, []);

    /**
     * Gets the react node for  product description.
     * @param  quickview - Quick view node.
     * @param  item - Product id to de displayed in quickview.
     * @returns The product quickview component.     */
    function renderQuickView(quickview: React.ReactNode, item?: number): JSX.Element | undefined {
        if (quickview === null) {
            return undefined;
        }
        return React.cloneElement(quickview as React.ReactElement, { selectedProductId: item });
    }

    // Construct telemetry attribute to render
    const payLoad = getPayloadObject('click', telemetryContent!, '', product.RecordId.toString());

    const attribute = getTelemetryAttributes(telemetryContent!, payLoad);
    //VSI CUSTOMIZATION -START - Check current loyalty tier id.
    const getTierId = () => {
        if (loyalty!?.LoyaltyGroups!?.length > 0) {
            const highestActiveLoyaltyCardTier = loyalty!.LoyaltyGroups![0]?.HighestActiveLoyaltyCardTier;
            const loyaltyCardTiers = loyalty!.LoyaltyGroups![0]?.LoyaltyCardTiers;
            const loyaltyCardTier = loyaltyCardTiers?.find(tier => tier.RecordId === highestActiveLoyaltyCardTier);
            return loyaltyCardTier?.LoyaltyTierRecordId;
        }
        return undefined;
    };
    //VSI CUSTOMIZATION -END
    const checkClubPlusDiscounts =
        discountLine && discountLine.length > 0 && discountLine?.find(x => x.OfferId === context.app.config.discountId);
    const checkUserLoyalty = getTierId() === context.app.config.LoyaltyTierId ? true : undefined;
    // cartData && cartData.AffiliationLines && cartData.AffiliationLines.find(x => x.LoyaltyTierId === context.app.config.LoyaltyTierId);
    // const checkNonClubPlusDiscounts = discountLine && discountLine.find(x => x.OfferId !== context.app.config.discountId);
    const membershipDiscount = discountLine && discountLine.find(x => x.OfferId === context.app.config.membershipDiscountId);
    //VSI CUSTOMIZATION -START - Task 11167: PLP hover image flip
    const outOfSeason =
        product.AttributeValues?.find(attr => attr.Name?.toLocaleLowerCase() === 'outofseason')?.TextValue?.toLowerCase() === 'yes';
    const getImgContainer = () => {
        if (!(typeName === 'dobbies-search-result-container' || images.isPrimaryUrl === false)) {
            return (
                <div className='msc-product__image'>
                    {renderProductPlacementImage(
                        imageSettings,
                        context.request.gridSettings,
                        product.PrimaryImageUrl,
                        product.Name,
                        context.request.apiSettings
                    )}
                </div>
            );
        }
        return (
            <div className='msc-product__image'>
                {renderProductPlacementImage(
                    imageSettings,
                    context.request.gridSettings,
                    product.PrimaryImageUrl,
                    product.Name,
                    context.request.apiSettings
                )}
                {renderProductPlacementImage(
                    imageSettings,
                    context.request.gridSettings,
                    images.primaryUrl,
                    product.Name,
                    context.request.apiSettings
                )}
            </div>
        );
    };

    const addProductToCartNewCarousel = async () => {
        const cartState = await getCartState(context.actionContext);
        const productList = new Array();
                             productList.push({
                                product: product,
                                count: carouselCurrQuantity,
                                enableStockCheck: context.app.config.enableStockCheck
                            });
        cartState
            ?.addProductsToCart(productList)
            .then(result => {
               setExistsInCartLines(true); 
            });
    }
    //VSI CUSTOMIZATION -END - Task 11167: PLP hover image flip
    return (
        product.ItemId ?
            <div onMouseOver={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
                <div
                    onClick={onTelemetryClick(telemetryContent!, payLoad, product.Name!)}
                    aria-label={renderLabel(
                        product.Name,
                        context.cultureFormatter.formatCurrency(product.Price, context.request.channel?.Currency),
                        product.AverageRating,
                        ratingAriaLabel
                    )}
                    data-product-id={product.ItemId}
                    className='msc-product'
                    {...attribute}
                >
                    <div className={isProductAddedToBundles ? 'productAdded' : ''}
                        style={{
                            position: 'relative'
                        }}
                    >
                        <div className='msc-product__image' ref={productImageRef}>
                            {isProductBundles &&
                                <div>
                                    <input className='msc-product__checkbox' type='checkbox' checked={isProductAddedToBundles} onClick={() => { checkboxClicked() }} />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="761.5 1255 13 16"><g data-name="Group 1749"><path d="m761.5 1265 5 6" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#fff" fill="transparent" data-name="Line 1331" /><path d="m774.5 1255-8 16" stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#fff" fill="transparent" data-name="Line 1332" /></g></svg>
                                </div>
                            }
                            <a
                                href={productUrl}
                                style={{
                                    display: 'block',
                                    outline: 'none'
                                }}
                            >
                                {/* //VSI CUSTOMIZATION -START - Task 11167: PLP hover image flip */}
                                {getImgContainer()}
                                {/* //VSI CUSTOMIZATION -END - Task 11167: PLP hover image flip */}
                            </a>

                            <VsiProductBadges productComponentProduct={product} context={context} moduleType={badgePageType.plp} />
                        </div>

                        {isHover && quickViewButton && renderQuickView(quickViewButton, product.RecordId)}

                        {(isHover || isMobile) && (
                            <div className='msc-wishlist-button fade-in-plp'>
                                <AddToWishlistComponent
                                    className='msc-add-to-cart-extra-actions'
                                    addToWishlistButtonText='Add to Wishlist'
                                    removeFromWishlistButtonText='Remove from Wishlist'
                                    addToWishlistMessage='Added'
                                    removedFromWishlistMessage='Removed'
                                    addItemToWishlistError='Error'
                                    removeItemFromWishlistError='Remove Error'
                                    nameOfWishlist='My Favourites'
                                    // @ts-ignore
                                    data={{ product, wishlists }}
                                    context={context}
                                    id={id}
                                    ariaRole='button'
                                    typeName={typeName}
                                // onError={onAddToWishlistFailed(callbacks, resources, product)}
                                />
                            </div>
                        )}
                    </div>

                    <div className='msc-product__content'>
                        <a className='msc-product__url'
                            href={productUrl}
                            style={{
                                width: '100%'
                            }}
                        >
                            <div
                                className='msc-product__details'
                            >
                                {/* <h4 className='msc-product__title'>{product.Name}</h4> */}
                                <h4 title={getProductName(product, product.Name!)} className='msc-product__title'>{getProductName(product, product.Name!)}</h4>

                                {renderPrice(
                                    context,
                                    typeName,
                                    id,
                                    checkUserLoyalty && membershipDiscount ? product.BasePrice : nonClubPlusDiscount?.AdjustedPrice!,
                                    checkUserLoyalty && membershipDiscount ? product.Price : nonClubPlusDiscount?.CustomerContextualPrice!,
                                    savingsText,
                                    freePriceText,
                                    originalPriceText,
                                    currentPriceText,
                                    checkUserLoyalty && membershipDiscount ? discountLine : nonClubPlusDiscount?.DiscountLines,
                                    cartData,
                                    updatedProductPrice,
                                    getTierId,
                                    checkClubPlusDiscounts
                                )}
                                {/* {renderDescription(product.Description)} */}
                                {!context.app.config.hideRating &&
                                    renderRating(context, typeName, id, product.AverageRating, product.TotalRatings, ratingAriaLabel)}

                                {/*do not show delivery and C&C icons on plp*/}
                                {showDeliveryIcons && (
                                    <div className='msc-product__delivery row'>
                                        {product!.isHomeDelivery ? (
                                            <div className='msc-product__isHD-active' />
                                        ) : (
                                            <div className='msc-product__isHD' />
                                        )}
                                        {(!product!.isHomeDelivery && outOfSeason) ? <div className='msc-product__isCnC' /> : product!.isCnC ? <div className='msc-product__isCnC-active' /> : <div className='msc-product__isCnC' />}
                                    </div>
                                )}
                            </div>
                        </a>
                        {isProductBundles &&
                            <div className='msc-product__product-quantity msc-cart-line__product-quantity'>
                                <label className='quantity-label'>Quantity:</label>
                                <Quantity
                                    id={`msc-cart-line__quantity_${product.RecordId}`}
                                    currentCount={currBundleQuantity}
                                    onChange={onQuantityChange}
                                    hideQuantityControls={false}
                                    disabledInput={false}
                                    max={maxQuantityForBundle}
                                    min={1}
                                />
                            </div>
                        }
                        {isNewCarouselDesign && <div className='msc-product__product-quantity msc-cart-line__product-quantity'>
                                <label className='quantity-label'>Quantity:</label>
                                <Quantity
                                    id={`msc-cart-line__quantity_${product.RecordId}`}
                                    currentCount={carouselCurrQuantity}
                                    onChange={onQChangeNewCarouselDesign}
                                    hideQuantityControls={false}
                                    disabledInput={false}
                                    max={context.app.config.maxQuantityForCartLineItem}
                                    min={1}
                                />
                            </div>}
                    </div>
                    {checkClubPlusDiscounts && checkUserLoyalty && (
                        <div className='product-card-club-plus-discount product-card-club-plus'>
                            <p>Club Plus Price</p>
                            <p className='product-card-club-plus-price'>£{product.Price.toFixed(2)}</p>
                        </div>
                    )}
                    {checkClubPlusDiscounts && checkUserLoyalty === undefined && (
                        <div className={product.clubPlusBanner ? 'product-card-club-plus-badge badge-collapse-pdp' : 'product-card-club-plus-badge'}>
                            {product.clubPlusBanner && (
                                <div className='product-club-plus-container-info'>
                                    <div onClick={() => hideBanner && hideBanner(product.RecordId)} className='product-card-club-plus-cross'></div>
                                    <p className='product-club-plus-info'>
                                        {/* Join Dobbies Club Plus for just £12 a year and enjoy 10% off on plants, bulbs and Seeds.{' '} */}
                                        Join Club Plus and save 10% on plants, bulbs and seeds all year round
                                        <span>
                                            {' '}
                                            <a href={badgeLink ? badgeLink : ''}>Find out more</a>
                                        </span>
                                    </p>
                                </div>
                            )}
                            <div className='product-card-club-plus'>
                                <p>Club Plus Price</p>
                                <p className='product-card-club-plus-price'>£{product.Price.toFixed(2)}</p>
                                <div onClick={() => showBanner && showBanner(product.RecordId)} className='product-card-club-plus-icon'>
                                    <p>?</p>
                                </div>
                            </div>
                        </div>
                    )}
                    {isNewCarouselDesign && !existsInCartLines ? <Button
                        className='ms-wishlist-items__product-add-button'
                        title={'Add to cart'}
                        onClick={() => {
                            addProductToCartNewCarousel();  
                        }}
                    >
                        Add
                    </Button>: <></>}
                </div>
            </div> : <></>
    );
};

function getProductName(product: IProductWithModes | undefined, productName: string): string | undefined {
    let prodName = '';
    //debugger;
    if ((product && product.AttributeValues === undefined) || (product && product.AttributeValues?.length == 0)) {
        prodName = productName;
    } else {
        if (product && product.AttributeValues) {
            product.AttributeValues?.map((val: AttributeValue) => {
                if (val.Name === 'EComName' && val.TextValue !== '') {
                    prodName = val.TextValue!;
                } else {
                    prodName = productName;
                }
            });
        } 
    }
    return prodName;
}

function renderLabel(name?: string, price?: string, rating?: number, ratingAriaLabel?: string): string {
    name = name || '';
    price = price || '';
    return `${name} ${price} ${getRatingAriaLabel(rating, ratingAriaLabel)}`;
}

// function renderDescription(description?: string): JSX.Element | null {
//     return <p className='msc-product__text'>{description}</p>;
// }

function getRatingAriaLabel(rating?: number, ratingAriaLabel?: string): string {
    if (rating && ratingAriaLabel) {
        const roundedRating = rating.toFixed(2);
        return format(ratingAriaLabel || '', roundedRating, '5');
    }
    return '';
}

function updateProductUrl(productUrl: string, context: ICoreContext): string {
    const srcUrl = new URL(productUrl, context.request.apiSettings.baseUrl);
    const queryString = `back=true`;
    if (srcUrl.search) {
        srcUrl.search += `&${queryString}`;
    } else {
        srcUrl.search += queryString;
    }

    const updatedUrl = new URL(srcUrl.href);
    return updatedUrl.pathname + srcUrl.search;
}

function renderRating(
    context: ICoreContext<{
        [x: string]: any;
    }>,
    typeName: string,
    id: string,
    avgRating?: number,
    totalRatings?: number,
    ariaLabel?: string
): JSX.Element | null {
    if (!avgRating) {
        return null;
        // display empty box to match the design of when rating isnt there on product component
    }

    const minNumOfStars = context.app.config.numberOfStarReviewsToShow;
    if (minNumOfStars && avgRating && avgRating < minNumOfStars) {
        return <></>;
    }

    const numRatings = (totalRatings && totalRatings.toString()) || undefined;
    const ratingAriaLabel = getRatingAriaLabel(avgRating, ariaLabel);

    return (
        <RatingComponent
            context={context}
            id={id}
            typeName={typeName}
            avgRating={avgRating}
            ratingCount={numRatings}
            readOnly={true}
            ariaLabel={ratingAriaLabel}
            data={{}}
        />
    );
}

function renderPrice(
    context: ICoreContext,
    typeName: string,
    id: string,
    basePrice?: number,
    adjustedPrice?: number,
    savingsText?: string,
    freePriceText?: string,
    originalPriceText?: string,
    currentPriceText?: string,
    discountLine?: DiscountLine[] | undefined,
    cartData?: Cart | undefined,
    updatedPrice?: number,
    getTierId?: () => number | undefined,
    clubPlusDiscountsOfferId?: DiscountLine | undefined | false
): JSX.Element | null {
    const price: ProductPrice = {
        BasePrice: basePrice,
        AdjustedPrice: adjustedPrice,
        CustomerContextualPrice: adjustedPrice
    };

    return (
        <PriceComponent
            context={context}
            id={id}
            typeName={typeName}
            data={{ price: price }}
            savingsText={savingsText}
            freePriceText={freePriceText}
            originalPriceText={originalPriceText}
            discountLine={discountLine}
            cartData={cartData}
            updatedPrice={updatedPrice}
            getTierId={getTierId}
            clubPlusDiscountsOfferId={clubPlusDiscountsOfferId}
        />
    );
}

//VSI CUSTOMIZATION -START - Task 11167: PLP hover image flip
async function validateMediaLocaionAsync(uri: string): Promise<[IImageData, boolean]> {
    const imageData = {
        src: uri || '',
        altText: ''
    };
    if (imageData.src === '') {
        return [imageData, false];
    }
    return new Promise<[IImageData, boolean]>(resolve => {
        try {
            const http = new XMLHttpRequest();
            http.open('HEAD', imageData.src, true);
            http.addEventListener('load', () => {
                resolve([imageData, http.status === 200 || http.status === 201]);
            });
            http.addEventListener('error', () => {
                resolve([imageData, false]);
            });
            http.send();
        } catch {
            resolve([imageData, false]);
        }
    });
}
//VSI CUSTOMIZATION -END - Task 11167: PLP hover image flip
function renderProductPlacementImage(
    imageSettings?: IImageSettings,
    gridSettings?: IGridSettings,
    imageUrl?: string,
    altText?: string,
    apiSettings?: ICommerceApiSettings
): JSX.Element | null {
    if (!imageUrl || !gridSettings || !imageSettings || !apiSettings) {
        return null;
    }

    const newImageUrl = generateImageUrl(imageUrl, apiSettings);

    const img: IImageData = {
        src: newImageUrl ? newImageUrl : '',
        altText: altText ? altText : ''
    };
    const imageProps = {
        gridSettings: gridSettings,
        imageSettings: imageSettings
    };
    imageProps.imageSettings.cropFocalRegion = true;

    // debugger;
    return <Image {...img} {...imageProps} loadFailureBehavior='empty' />;
}

// @ts-ignore
export const ProductComponent: React.FunctionComponent<IProductComponentProps> = msdyn365Commerce.createComponentOverride<
    IProductComponent
>('Product', { component: ProductCard, ...PriceComponentActions });

export default ProductComponent;
